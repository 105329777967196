<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <AppHeader
      is-back-btn-disabled
      :anonymity="anonymity"
    />
    <TTView>
      <VRow>
        <VCol cols="12">
          <div
            v-if="texts.questionTitle"
            v-html="texts.questionTitle"
          />
          <h3
            v-else
            class="title mb-3 font-weight-medium mt-14"
          >
            {{ $t('chooseYourDepartment') }}
          </h3>

          <h5 class="font-weight-regular mb-2">
            {{ texts.questionHint }}
          </h5>

          <template v-for="(combobox, idx) in comboboxes">
            <VCombobox
              :key="combobox.key"
              ref="comboboxes"
              v-model="combobox.team"
              class="py-3"
              :items="combobox.searchedList"
              background-color="#F5F6F6"
              outlined
              filled
              dense
              hide-details
              single-line
              :menu-props="{ contentClass : 'menu__item' }"
              :placeholder="texts.selectPlaceholder || $t('searchForDepartment')"
              :append-icon="idx ? '$dropdown' : '$search'"
              item-text="fullName"
              item-value="id"
              @update:search-input="debouncedSearch($event, combobox)"
              @change="change($event, combobox)"
            >
              <template #item="{ item }">
                <div
                  v-if="item.id"
                  class="mt-2"
                >
                  <div class="tt-black--text text--lighten-2 text-caption">
                    {{ item.parentName }}
                  </div>

                  <div class="text-subtitle-2 font-weight-regular">
                    {{ item.fullName }}
                  </div>
                </div>
              </template>

              <template #selection="{ item }">
                {{ item.fullName }}
              </template>
            </VCombobox>
          </template>

          <div
            v-if="comboboxes.length === 1"
            class="tt-black--text text--lighten-2 text-caption mb-2"
          >
            {{ texts.selectHint || $t('inputDepartmentName') }}
          </div>
        </VCol>

        <VCol
          v-if="selected && selected.id"
          cols="12"
        >
          <h3 class="title mb-3 font-weight-medium">
            {{ $t('chosenDepartment') }}
          </h3>
          <div class="bordered rounded-lg pa-5 d-flex justify-space-between">
            <div>
              <div class="tt-black--text text--lighten-2 text-caption">
                {{ selected.parentName }}
              </div>
              {{ selected.name }}
            </div>
            <VBtn
              text
              icon
              class="align-self-center"
              @click="resetTeams"
            >
              <VIcon size="24">
                $close
              </VIcon>
            </VBtn>
          </div>
        </VCol>
      </VRow>

      <VRow>
        <VCol class="mt-8">
          <ActionsSurvey
            :is-disabled="!selected"
            @move="handleNext"
          />
        </VCol>
      </VRow>
      <VSnackbar
        v-if="isTestingFulfillment"
        timeout="-1"
        top
        centered
        outlined
        color="#EBECED"
        content-class="tt-black--text"
        :value="true"
      >
        <VIcon
          color="tt-red"
          size="20"
          class="mr-2"
        >
          $exclamationCircle
        </VIcon>
        <span class="text-body-1 font-weight-medium">
          {{ $t('testingSnackBarTitle') }}
        </span>
      </VSnackbar>
    </TTView>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { mapMutations } from 'vuex';
import AppHeader from '@/components/app/AppHeader.vue';
import TTView from '@/components/TTView.vue';
import * as apiService from '@/services/api';
import errorHandler from '@/helpers/errorHandler';
import { toggleLoading, updateProgressBar, setCompanyId } from '@/plugins/vuex/mutationTypes';
import { MOVE_TYPES } from '@/utils/constants';
import ActionsSurvey from '@/views/UI/ActionsSurvey.vue';

export default {
  name: 'SurveyOrgstructure',

  components: {
    TTView,
    AppHeader,
    ActionsSurvey,
  },
  inject: ['Names'],

  data() {
    return {
      texts: {},
      onlyFinalNodes: false,
      anonymity: true,
      surveySlug: {},
      selected: null,
      comboboxes: [
        {
          key: 0,
          team: null,
          searchedList: [],
        },
      ],
      firstPageIsOrgstructure: false,
    };
  },

  computed: {
    userSurveySlug() {
      return this.$route.params.slug;
    },
    teamId() {
      return this.selected?.id;
    },
    isTestingFulfillment() {
      return this.$route.query.testing;
    },
  },

  created() {
    this.initSurvey();
    this.getTexts();
  },

  methods: {
    ...mapMutations([updateProgressBar, toggleLoading, setCompanyId]),
    async getTexts() {
      try {
        const { data } = await apiService.surveyOrgstructurePageGet(this.userSurveySlug);
        this.texts = data?.orgstructurePage?.blocks?.[0] || {};
        this.anonymity = data?.orgstructurePage?.anonymity;
        this.onlyFinalNodes = data?.orgstructurePage?.onlyFinalNodes;
      } catch (err) {
        errorHandler(err);
      }
    },
    async initSurvey() {
      this[toggleLoading](true);

      try {
        const postResponse = await apiService.surveySessionWelcomeGet(this.userSurveySlug);
        const {
          surveyName,
          orgstructureRequired,
          orgstructurePageOrder,
          companyId,
        } = postResponse.data;
        this[setCompanyId](companyId);
        this.firstPageIsOrgstructure = orgstructureRequired && (orgstructurePageOrder === 0);
        this[updateProgressBar]({ surveyName });
      } catch (err) {
        errorHandler(err);
      } finally {
        this[toggleLoading](false);
      }
    },
    async handleNext() {
      this[toggleLoading](true);
      try {
        await apiService.surveyByUserSlugUpdate(this.userSurveySlug, {
          params: { team_id: this.teamId, testing: this.isTestingFulfillment },
        });
        if (!this.firstPageIsOrgstructure) {
          await apiService.surveyMove(this.userSurveySlug, {
            moveType: MOVE_TYPES.FORWARD,
          });
        }
        const query = this.isTestingFulfillment ? { testing: true } : {};
        this.$router.push({
          name: this.Names.R_USER_SURVEY_PAGE,
          params: { slug: this.userSurveySlug },
          query,
        });
      } catch (err) {
        errorHandler(err);
      } finally {
        this[toggleLoading](false);
      }
    },
    debouncedSearch(e, combobox) {
      debounce(() => {
        this.search(e, combobox);
      }, 250)();
    },
    async search(e, combobox) {
      const currentComboboxIdx = this.comboboxes.indexOf(combobox);
      const previousComboboxIdx = currentComboboxIdx - 1;
      if (!e && combobox?.team?.id) {
        return;
      }
      const parentId = this.comboboxes[previousComboboxIdx]?.team.id;
      const params = {
        user_survey_slug: this.userSurveySlug,
        search_query: e,
      };
      if (parentId) {
        params.external_id = parentId;
      }
      try {
        const {
          data: { teams },
        } = await apiService.teamGetByName(
          {},
          {
            params,
          },
        );
        this.comboboxes[currentComboboxIdx].searchedList = teams;
      } catch (err) {
        errorHandler(err);
      }
    },
    change(e, combobox) {
      const cbIndex = this.comboboxes.indexOf(combobox);
      this.comboboxes.length = cbIndex + 1;
      this.selected = null;
      if (e && typeof e !== 'string') {
        if (!combobox.team.hasChildren) {
          this.selected = combobox.team;
        } else {
          if (!this.onlyFinalNodes) {
            this.selected = combobox.team;
          }
          this.comboboxes.push({
            key: combobox.team.id,
            team: null,
            searchedList: [],
          });
        }
      }
      this.$nextTick(() => {
        this.$refs.comboboxes[this.$refs.comboboxes.length - 1].focus();
      });
    },
    resetTeams() {
      this.selected = null;
      this.comboboxes = [
        {
          key: 0,
          team: null,
          searchedList: [],
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-list-item--active.v-list-item--link {
  color: #0b1218 !important;

  &:before {
    background-color: unset;
  }

  &:hover {
    background-color: #f5f6f6 !important;
  }
}

::v-deep .v-select__slot {
  .v-select__selection--comma {
    overflow: unset;
    text-overflow: unset;
  }

  .v-input__append-inner {
    .v-icon.v-icon {
      font-size: 20px;
      transform: none;
    }
  }
}

::v-deep .v-ripple__container {
  display: none !important;
}

.title {
  font-size: 20px;
}

.bordered {
  border: 1px solid map-get($tt-black, 'lighten-4');
}

.v-text-field--outlined ::v-deep fieldset {
  border: 1px solid map-get($tt-black, 'lighten-5') !important;
}
</style>
